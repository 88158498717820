body {
  margin: 0;
  height: 100vh;
  position: flex;
  background-color: "#ebebeb";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
